.datamanagement {
    &__type {
        min-width: 225px;
        width: auto;
        font-family: Arial;
        background-color: #002960;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        flex-direction: row;
    }
    &__label {
        height: 14px;
        margin-left: 2px;
        width: 70px;
        color: #002960;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 14px;
        margin-right: 10px;
    }
}
