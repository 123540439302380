.collapisble {
    height: auto;
    border: 1px solid rgba(151, 151, 151, 0.23);
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 3px 13px 3px rgba(0, 0, 0, 0.04);
    &-collapsed {
        height: 0;
        border-top: 0;
        overflow: hidden;
        padding: 0px !important;
    }
    &__title {
        font-family: Arial;
        display: flex;
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
    }
    &__icon {
        justify-content: flex-end;
        margin-right: 20px;
    }
    &__header {
        cursor: pointer;
        height: 28px;
        display: flex;
        align-items: center;
        color: white;
        padding: 0px 20px;
        border-bottom: 1px solid #f0f0f0;
        background-color: #00b1f6;
        color: white;
        /*
            box-sizing: border-box;
            height: 49px;
            
            border: 1px solid rgba(151,151,151,0.23);
            border-radius: 4px 4px 0 0;
            background-color: #3389D2;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
            color: white;
        */
    }
    &__content {
        padding: 10px 0;
    }

    &_fa-angle-up-font {
        font-size: 1.5em;
        border-radius: 50%;
    }
}
.container {
    max-width: 100% !important;
}
