.header {
    height: 67px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 3px 13px 3px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    font-family: 'Arial';
    border-bottom: 1px solid rgba(151, 151, 151, 0.23);
    box-sizing: border-box;
    z-index: 1;

    &__logo {
        cursor: pointer;
    }
    &-logo {
        max-width: 100px;
        max-height: 100px;
    }

    a {
        text-decoration: none;
    }

    &__link {
        padding: 25px;
        height: 14px;
        width: 112px;
        color: #002960 !important;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.6px;
        line-height: 14px;
        cursor: pointer
    }

    &__firstlink {
        margin-left: 70px;
    }

    &__otherlinks {
        margin-left: 25px;
    }

    &-active {
        border-bottom: 3px solid #3389d2;
    }

    &__text {
        margin-left: 20px;
        cursor: pointer;
        text-transform: uppercase;
        height: 15px;
        width: 140px;
        color: #002960;
        font-family: Arial;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.07px;
        line-height: 15px;
    }

    &__user-info {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
    }

    &__user-img {
        box-sizing: border-box;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 41px;
        border: 3px solid #ffffff;
        margin-right: 20px;
        background-color: #3389d2;
        box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.07);
        border-radius: 100px;
        cursor: pointer;
    }

    &__user-img-text {
        height: 16px;
        width: 16px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 16px;
        text-align: center;
        font-family: 'Roboto';
    }
    &__user-text {
        height: 11px;
        font-family: 'Arial';
        color: #3c5169;
        display: flex;
        justify-content: flex-end;
        margin-right: 6px;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 11px;
    }
    &__menu {
        color: #3c5169;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }
}

.header-mob {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    &-nav {
        background-color: #f7faff !important;
    }
    &__user {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        flex-direction: column;
        &-image {
            box-sizing: border-box;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 41px;
            border: 3px solid #ffffff;
            background-color: #3389d2;
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.07);
            border-radius: 100px;
            margin-bottom: 10px;
            cursor: pointer;
        }
    }
    &__menu {
        padding: 10px;
        border-bottom: 1px solid rgba(151, 151, 151, 0.23);
        font-size: 12px;
        display: flex;
        text-transform: uppercase;
        color: #939598;
        font-family: Arial;
    }
    &-active {
        color: #0b2d71;
        font-weight: bold;
    }
}
.dot {
    height: 8px;
    width: 8px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
  }

@media screen and (max-width: 700px) {
    .header__text {
        font-size: 12px;
    }
    .header-logo {
        max-width: 60px;
    }
}
