.master-data {
    &__action {
        display: flex;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 #e8e8e8;
        align-items: center;
        border: 1px solid rgba(151, 151, 151, 0.23);
        border-bottom: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}
