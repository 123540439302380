.main-Container {
  border: "1px solid #c3c3c3";
  display: flex;
  flex-direction: row;
}

.table-wrapper {
  overflow-y: scroll;
  height: 580px;
  width: 80%;
  border: 1px solid #e5e5e5;
}
.table-wrapper th {
  position: sticky;
  top: 0px;
}
.main-table {
  width: 100%;
  border-color: #e5e5e5;
}
.table-heading-select {
  text-align: center;
  padding: 10px;
  background-color: #e5e5e5;
  font-size: 15px;
  width: 5%;
}
.table-heading-drivers {
  text-align: center;
  padding: 10px;
  background-color: #e5e5e5;
  font-size: 15px;
  width: 20%;
}
.table-heading-dates {
  font-size: 12px;
  background-color: #e5e5e5;
  text-align: center;
}
.table-rows:nth-child(even) {
  background-color: #f9f9f9;
  width: 100%;
}
.checkbox-style {
  text-align: left;
  padding: 12px;
  background-color: #f5f5f5;
  border:1px solid #e5e5e5;
  width: 5%;
}
.table-coloumn-driver {
  background-color: #f5f5f5;
  font-size: 13px;
  text-align: center;
  border:1px solid #e5e5e5;
  width: 20%;
}
.not-available-main-container {
  width: 100%;
  margin-top: 10px;
}
.not-available-bar {
  width: 100%;
  height: 13px;
  background-color: #f96461;
  border-radius: 50px;
}
.not-available-label {
  font-weight: bold;
  font-size: 9px;
  color: #ffff;
  display: flex;
  text-align: left;
  padding-left: 4px;
  padding-bottom: 3px;
}
.available-bar {
  width: 100%;
  height: 13px;
  background-color: #4caf50;
  border-radius: 50px;
}
.right-view-container {
  border: 1px solid #e5e5e5;
  width: 23%
}
.right-view-main-container {
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  background-color: #e5e5e5;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  justify-items: flex-end;
  height: 43px;
}
.right-availablity-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.right-available-circle {
  width: 10px;
  height: 10px;
  border-radius: 25px;
  background-color: #3dc651;
}
.right-not-available-circle {
  width: 10px;
  height: 10px;
  border-radius: 25px;
  background-color: #f96461;
}
.right-availablity-label {
    margin-top: 7px;
  font-size: 12px;
  margin-left: 5px;
}
.day-off-driver-name-container {
  margin-left: 25px;
  margin-top: 25px;
}
.day-off-label {
  font-size: 15px;
  font-weight: bold;
}
.radio-button-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.radio-button-container label {
  font-size: 12px;
}
.start-end-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 20;
}
.start-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.start-end-day-off-label {
  color: #7e7e7e;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}
.date-picker-style {
  width: 100px;
  height: 30px;
  border: 1px solid #1eb2f3;
  border-radius: 5px;
  margin-top: 10px;
}
.end-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  margin-left: 10px;
}
.start-end-date-scrollable-container {
  // overflow-y: scroll;
  // height: 200px;
  border-bottom: 1px solid #e5e5e5;
}
.start-end-date-input-container {
  display: flex;
  flex-direction: row;
}
.day-off-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 1px;
  align-items: center;
  margin-bottom: 5px;
}
.day-off-value {
  color: #000000;
  font-size: 12px;
  margin-left: 40px;
  margin-top: 5px;
  margin-right: 30px;
}
.additional-day-off-container {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.total-count-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 10px;
  align-items: center;
}
.additional-day-off-label{
  color: #1eb2f3;
  font-size: 12px;
  width: 170px;
  align-self: flex-start;
  text-align: left;
  border: 1px solid transparent;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
}
.disabled-additional-day-off-label{
  color: #e5e5e5;
  font-size: 12px;
  width: 170px;
  align-self: flex-start;
  text-align: left;
  border: 1px solid transparent;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
}
.total-days-off{
    color: #000000;
    margin-left: 12px;
    font-weight: bold;
    font-size: 12px;
}
.button-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
}
.reset-button-style {
  color: #7e7e7e;
  font-weight: bold;
  padding: 10px 16px;
  text-align: center;
  border: 1px solid #7e7e7e ;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  padding: 10px 16px;
  text-align: center;
  border-radius: 5px;
  margin: 4px 2px;
  cursor: pointer;
  background-color: #f9f9f9;
}
.assign-button-style {
  border: none;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  background-color: #1eb2f3;
  color: #ffff;
  font-weight: bold;
  padding: 10px 16px;
  text-align: center;
  border-radius: 5px;

}
.disabled-assign-button-style {
  border: none;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  background-color: #e5e5e5;
  color: #ffff;
  font-weight: bold;
  padding: 10px 16px;
  text-align: center;
  border-radius: 5px;
}
.day-picker {
  display: inline-block;
  font-size: 0.7rem;
  width: 10;
  height: 10
}
.timeline-bar-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  align-items: center;
}
.padding-bottom{
  margin-bottom: 10px;
  margin-top: 10px;
}
.select-dropdown {
  min-width: 50px;
  padding-left: 5px;
  padding-right: 5px;
  width: auto;
  font-family: Arial;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  flex-direction: row;
}
.select-carrier {
  min-width: 350px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  font-family: Arial;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  flex-direction: row
}
.select-style{
background-color: #ffffff;
color: #000000;
font-size: 13px;
height: 35px;
width: 100px;
border-radius: 5px;
font-family: 'Arial';
margin-left: 10px;
border: 1px solid #002960;
appearance: none;
// /* SVG background image */
background-image: linear-gradient(50deg, transparent 50%, black 50%),
linear-gradient(130deg, black 50%, transparent 50%),
linear-gradient(to right, transparent, transparent);
background-position:
  calc(100% - 15px) calc(1em + 2px),
  calc(100% - 10px) calc(1em + 2px),
  100% 0;
background-size:
  5px 5px,
  5px 5px,
  2.5em 2.5em;
background-repeat: no-repeat;
}
.timeline-label-div-style{
color: #002960;
font-size: 15px;
height: 23px;
width: 110px;
align-items: center;
border-radius: 5px;
font-family: 'Arial';
font-weight: bold;
}
.delete-icon{
margin-right: 15px;
}
.icon-div {
  margin-right: 5px;
}
.search-input {
width: 100%;
height: 38px;
font-size: 11;
border: 1px solid black; 
border-radius: 5px;
background-color: #F6F6F6;
}
.search-input-textField {
width: 100%;
height: 35px;
font-size: 11;
border: 1px solid #F6F6F6; 
border-radius: 5px;
background-color: #F6F6F6;
}
.search-icon {
width: 15px;
height: 15px;
color: grey;
align-self: center; 
margin-right: 5px;
}