.maintenance-data {
    &__type {
        width: 225px;
        font-family: Arial;
        background-color: #002960;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        flex-direction: row;
    }
    &__label {
        height: 14px;
        margin-left: 2px;
        color: #002960;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 14px;
        margin-right: 10px;
    }
    &_prepostlist {
        margin-left: 10px;
    }
    &_scroll {
        overflow: scroll;
        height: 400px !important;
        padding-right: 4px;
    }
}

.radio-right {
    display: flex;
    justify-content: flex-start;
    margin-right: 10px;
}
.thumbnail:hover {
    position: relative;
    top: 1px;
    left: -35px;
    width: auto;
    height: auto;
    display: block;
    z-index: 9999;
}

.divhors .divhor {
    display: inline-block;
}

.filtercss {
    margin-left: 1%;
    margin-right: 1%;
}
.divborder {
    border: medium;
}
.green {
    color: blue;
}
.Uimargin {
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 2%;
}
.wrapper {
    margin: 0 auto;
    display: flex;
}
.wrapper div {
    padding: 10px;
    border-radius: 5px;
}
legend {
    // display: block!important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    border: 0px !important;
    font-size: medium;
    width: auto;
}
fieldset {
    border: medium !important;
    border: 1px solid !important;
    margin: 0em !important;
}
.login-legend {
    margin: 20px;
    width: 155px;
}

.imageauto {
    width: 100%;
    height: 400px;
    object-fit: contain;
}

label {
    font-size: 12px;
}
span {
    font-size: 12px;
}

.defect-column-description {
    display: flex;
    align-items: center;
}

.defect-column-first-radio {
    display: flex;
    align-items: center;
    margin-bottom: 0em;
}

input[type='radio'] {
    margin: 0 10px 0px 15px;
}

.defect-item-container {
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ecfaf6;
    border-left: 5px solid #ffa6af;
}

.open-tickets-data-grid {
    > div:first-child {
        height: 50vh !important;
    }
}