.shop-assignment-popup {
    &__parent {
        border: 3px black solid;
        height: auto;
        padding: 25px;
        text-align: left;
    }
    &__type {
        width: auto;
        font-family: Arial;
        background-color: #002960;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        flex-direction: row;
    }
    &__label {
        height: 14px;
        margin-left: 2px;
        width: 150px;
        color: #002960;
        font-family: Arial;
        font-size: 14px;
        font-weight: bolder;
        letter-spacing: 0.3px;
        line-height: 14px;
        margin-right: 10px;
    }
    &__buttongroup {
        display: flex;
    }
}
.ticket-container-header {
    background-color: #00B1F6;
    border: solid 1px lightgrey;
    color: white;
    font-size: 16px;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.completeTicket {
    width: 100%;
    padding: 6px;
    border-radius: 4px !important;
    &_input{
    width: 100%;
    padding: 6px;
    border: 1.2px solid #002960;
    border-radius: 4px !important;
    }
}

#ticket-modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

#ticket-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-family: Arial;
}

.ticket-modal-body {
    background-color: white;
    height: 100%;
}