.reportmanagement {
    &__type {
        min-width: 225px;
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
        font-family: Arial;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        flex-direction: row;
    }
    &__consignee {
        min-width: 350px;
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
        font-family: Arial;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        flex-direction: row
    }
    &__label {
        height: 14px;
        margin-left: 2px;
        width: 70px;
        color: #002960;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 14px;
        margin-right: 10px;
    }
}

.padding-bottom{
  margin-bottom: 10px;
  margin-top: 10px;
}
.input-date{
    font-size: 14px;
    border: 0.5 solid grey;
}
.button-next{
    border-left-width: 10;
     border-right-width: 10;
     border-bottom-width: 20;
     border-style: 'solid';
     background-color: 'transparent';
     border-left-color: 'transparent';
        border-right-color: 'transparent'
}
.InputFromTo{
    margin : 10px 0px;
}
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 350px;
  }
  .InputFromTo .DayPickerInput input {
    height: 35px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -170px;
    // width: 30px
  }
  .customer-report {
    margin-top : 0px;
    font-size : 12px;
    padding-left: 20px;
    background-color: white;
    height: 15px;
    text-align: center;
  }
