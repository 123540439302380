.maintenance-data {
    &__type {
        width: 225px;
        font-family: Arial;
        background-color: #002960;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        flex-direction: row;
    }
    &__label {
        height: 14px;
        margin-left: 2px;
        color: #002960;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 14px;
        margin-right: 10px;
    }
    &_prepostlist {
        margin-left: 10px;
    }
    &_scroll {
        overflow: scroll;
        height: 400px !important;
        padding-right: 4px;
    }
    &__filter-legend {
        height: 14px;
        margin-left: 2px;
        color: #002960;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 14px;
        margin-right: 10px;
        margin-left: 10px;
    }
}

.ticket_number {
    
    height: 14px;
    width: 112px;
    color: #002960;
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.6px;
    line-height: 14px;
}

.radio-right {
    display: flex;
    justify-content: flex-start;
    margin-right: 10px;
}
.thumbnail:hover {
    position: relative;
    top: 1px;
    left: -35px;
    width: auto;
    height: auto;
    display: block;
    z-index: 9999;
}

.collapisble__content {
    padding: 2px;
}

.divhors .divhor {
    display: inline-block;
}

.filtercss {
    margin-left: 1%;
    margin-right: 1%;
    height: 55px;
    width: 450px;
}

.filtercssShort {
    margin-left: 1%;
    margin-right: 1%;
    height: 55px;
    width: 300px;
}

.divborder {
    border: medium;
}
.green {
    color: blue;
}
.Uimargin {
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 0.5% !important;
}
.wrapper {
    margin: 0 auto;
    display: flex;
}
.wrapper div {
    padding: 10px;
    border-radius: 5px;
}
legend {
    // display: block!important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    border: 0px !important;
    font-size: medium;
    width: auto;
}
fieldset {
    border: medium !important;
    border: 1px solid !important;
    margin: 0em !important;
}
.login-legend {
    margin: 20px;
    width: 155px;
}

.imageauto {
    width: 100%;
    height: auto !important;
    object-fit: contain;
}

label {
    font-size: 12px;
}
span {
    font-size: 12px;
}

.defect-column-description {
    display: flex;
    align-items: center;
}

.defect-column-first-radio {
    display: flex;
    align-items: center;
    margin-bottom: 0em;
}

input[type='radio'] {
    margin: 0 10px 0px 15px;
}

.defect-item-container {
    height: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ecfaf6;
    border-left: 5px solid #ffa6af;
    border-bottom: 1px solid #dee2e6 !important;
    box-shadow: 1px 1px 1px #dee2e6;
}

.defect-item-containerWhite {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    border-left: 5px solid #dee2e6;
    border-bottom: 1px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
    box-shadow: 1px 1px 1px #dee2e6;
}

.item-container:not(:first-child) {
    margin-top: 3px !important;
}
.modal-content{
    width: 500px !important;
}


.filter-select-container{
    margin-top: -9px !important
}
.filter-select-wrapper{
    margin-top: -5px
}

.container-fluid{
    margin-bottom: 10px !important 
}

.filter-wrapper{
    display: flex;
}

.filter-item{
    margin-left: 10px;
    margin-right: 10px;
}

.hazardAssessmentFieldscss{
    margin-left: 1%;
    margin-right: 1%;
    height: 55px;
    width: 100%;
}