.register-container {
    background: url(../../../assets/fullscreen-bg.jpg) 50% 0px no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    overflow: auto;
    align-items: center;
     ::placeholder {
        padding-left: 5px;
    }
    &__content {
        width: 420px;
        overflow: hidden;
        font-family: Arial;
        border: 1px solid hsla(0, 0%, 59.2%, 0.23);
        border-radius: 6px;
        background-color: #fff;
        padding: 20px;
        box-shadow: 0 3px 13px 3px rgba(0, 0, 0, 0.04);
        font-size: 12px;
        margin: auto;
        &>form {
            margin-top: 20px;
        }
        .form-group {
            margin-bottom: 10px;
            &>select {
                flex: 1;
            }
        }
        .col {
            padding: 0px;
        }
    }
    label {
        padding-top: 10px;
        font-size: 13px;
        color: #4d5054;
    }
    &-logo {
        max-width: 200px;
        height: 50px;
    }
    &-header {
        margin-top: 20px;
        font-size: 16px;
        font-weight: bolder;
        color: #0b2d71;
        padding-bottom: 10px;
    }
    input[type="text"],
    select {
        width: 100%;
        height: 30px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    select[multiple] {
        height: auto;
    }
    button {
        height: 35px;
        width: 100px;
        border-radius: 5px;
    }
    &-invalid-email {
        border: 1px solid red !important;
    }
    &-error-message {
        display: block;
        font-size: 10px;
        color: red;
    }
    #emailid {
        padding-left: 15px;
    }
    .div-center-align {
        margin: auto;
        text-align: center;
    }
    .div-button-center-align {
        font-size: 14px;
        text-align: center;
    }
}