.searchlistbox {
    height: auto;
    border: 1px solid rgba(151, 151, 151, 0.23);
    background-color: #FFFFFF;
    box-shadow: 0 3px 13px 3px rgba(0, 0, 0, 0.04);
    vertical-align: middle;
    text-align: center;
    width: 50%!important;
}
.selectlistbox {
    height: 8%;
    color: white;
    width: 100% !important;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    border-radius: inherit;
    align-content: end;
    vertical-align: middle;
    text-align: center;
    height: 43px;
    width: 233px;
    border-radius: 4px 4px 0 0;
    background-color: #3389D2;
    font-family: Arial;
    font-weight: bold;
    font-size: 10px;
}
.highlight{
    font-weight: bold !important; background-color: lightgray !important
}
.red-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
}
.normal{
    font-weight: normal !important;background-color: whitesmoke !important
}

.textSpan:after {
    content: '\2807';
    font-size: 3em;
    color: #2e2e2e
   }