.dispatch-driver-popup {
    &__parent {
        border: 3px black solid;
        height: auto;
        padding: 25px;
        text-align: left;
    }
    &__type {
        width: auto;
        font-family: Arial;
        background-color: #002960;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        flex-direction: row;
    }
    &__label {
        height: 14px;
        margin-left: 2px;
        width: 150px;
        color: #002960;
        font-family: Arial;
        font-size: 14px;
        font-weight: bolder;
        letter-spacing: 0.3px;
        line-height: 14px;
        margin-right: 10px;
    }
    &__buttongroup {
        display: flex;
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}
