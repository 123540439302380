.user-request {
    background: url(../../../assets/fullscreen-bg.jpg) 50% 0px no-repeat;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    &_content {
        margin: auto;
        border: 1px solid hsla(0, 0%, 59.2%, 0.23);
        border-radius: 6px;
        background-color: #fff;
        padding: 20px;
        box-shadow: 0 3px 13px 3px rgba(0, 0, 0, 0.04);
        color: #495057;
    }

    &_header {
        color: #0b2d71;
        font-weight: bold;
    }

    &_invalid {
        color: red;
        font-weight: bold;
        width: 200px;
    }

    &_footer {
        padding-top: 15px;
    }
}
.label-value-pair {
margin-top: 1px;
margin-left: 5px;
margin-right: 5px;
padding: 5px;
font-size: 18px;
display: flex;
justify-content: flex-start;
margin-left: 26px;
}

.label-text {
padding: 5px;
font-size: 16px;
}

.label-value {
padding: 5px;
font-size: 16px;
font-weight: bold;
}

.button-reject {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
    color: #fff;
    background-color: rgb(214, 73, 30);
    border-color: rgb(214, 73, 30);
}

.button-accept {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
    color: #fff;
    background-color: rgb(50, 170, 50);
    border-color: rgb(50, 170, 50);
}

.user-loading {
    color: royalblue;
}
  
.filterclass {
    width: 95%;
    font-size: 15px;
    box-sizing: border-box;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    background-color: #F6F5F5;
    margin-bottom: 8px;
    height: 120px !important;
}