.ticket-container {
    &-text-box {
        display: grid !important;
    }
    &__select {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        margin-right: 10px;
    }
}
.trailer-mapped{
    &-yes {
        background-color: #17e504 !important;
    }
    &-no {
        background-color: #ff0033 !important;
    }
}
.marginRight{
    margin-right: 20px;
}