.hazard-data {
    &__filter-legend {
        height: 14px;
        margin-left: 2px;
        color: #002960;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 14px;
        margin-right: 10px;
        margin-left: 10px;
    }   
    &_scroll {
        overflow: scroll;
        height: 400px !important;
        padding-right: 4px;
    }
}

.container-fluid{
    margin-bottom: 10px !important; 
}

.ticket_number {
    height: 14px;
    width: 112px;
    color: #002960;
    font-family: Arial;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.6px;
    margin-left: 20px !important;
    line-height: 14px;
}
.downloadpdf {
    margin-left: 100px !important; 
}
.Uimargin {
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 0.5% !important;
}

legend {
    padding-left: 2px !important;
    padding-right: 2px !important;
    border: 0px !important;
    font-size: medium;
    width: auto;
}

fieldset {
    border: medium !important;
    border: 1px solid !important;
    margin: 0em !important;
}

label {
    font-size: 12px;
}

span {
    font-size: 12px;
}

input[type='radio'] {
    margin: 0 10px 0px 15px;
}

.filter-wrapper{
    display: flex;
}

.filter-item{
    margin-left: 10px;
    margin-right: 10px;
}

.hazardAssessmentFieldscss{
    margin-left: 1%;
    margin-right: 1%;
    height: 55px;
    width: 100%;
}

.hazardAssessmentFieldLargescss{
    overflow:scroll;
    margin-left: 1%;
    margin-right: 1%;
    height: 100px;
    width: 100%;
}