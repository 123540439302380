.trailer-ques-bgColor{
    &-yellow {
        background-color: #ffd900 !important;
    }
    &-red {
        background-color: #ff0033 !important;
    }
    &-blue {
        background-color: lightskyblue !important;
    }
    &-green {
        background-color: rgb(61, 206, 61) !important;;
    }
    &-orange {
        background-color: orange !important;
    }
    &-gray {
        background-color: #cac5c5 !important; //rgb(113, 112, 112) !important;
    }
    &-available {
        background-color: #F5F5F5 !important;
    }
}
