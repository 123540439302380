.confirmPopup {
   width: 600px;
   background-color: #FFFFFF;	
   box-shadow: 0 0 10px 0 #9B9B9B;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 15px;
   max-height: calc(100vh - 80px);
   &-warning {
       color: #D0021B;
   }
   &__warning {
       margin-top: 10px;
   }
   &__primary-text {
        color: #4A4A4A;	
        display: flex;
        font-family: Arial;	
        margin-top: 10px;
        text-align: left;
        font-size: 14px;	
        font-weight: bold;	
        line-height: 17px;	
   }
   &__secondary-text {
        color: #606060;	
        display: flex;
        font-family: Arial;	
        margin-top: 10px;
        font-size: 12px;	
        line-height: 17px;
   }
   &__action {
       margin-top: 15px;
       margin-bottom: 15px;
       width: 270px;
   }
   &__type {
        width: 250px;
        // padding-left: 10px;
        // padding-right: 10px;
        margin-left: 5px;
        font-family: Arial;
        font-size: 13px;
        line-height: 18px;
        flex-direction: row;
    }
}

.entryField {
    margin-left: 5px;
    border: 1px solid lightgray;
    width: 250px;
    height: 36px;
}
.weekEntryField {
    border: 1px solid lightgray;
    width: 75px;
    height: 36px;
    margin-left: -25px;
    padding-left: 5px;
    padding-right: 100px;
}
.timeEntryField {
    border: 1px solid lightgray;
    width: 135px;
    height: 36px;
}
.searchSelectionFieldList {
    margin-left: 6px;
    border: 1px solid lightgray;
    width: 250px;
    margin-bottom: 15px;
}
.fieldsList {
    max-height: 90px;
    overflow-y: auto;
    width: 250px;
}
.email-input {
    width: 250px;
    height: 35px;
    border: 1px solid lightgray;
}
.fieldsItem {
    width: 240px;
    border: 1px solid lightgray;
    height: 30px;
    font-size: 13px;
}
.select-all-fieldsItem {
    width: 240px;
    border: 1px solid lightgray;
    height: 30px;
    font-size: 13px;
    font-weight: bold;
}
.invalid-email {
    border: 1px solid red !important;
}
.email-error{
    display: block;
    font-size: 10px;
    color: red;
}
.search-field-input {
    width: 250px;
    height: 35px;
    font-size: 11;
    border: 1px solid lightgray; 
    border-radius: 3px;
    background-color: #F6F6F6;
}

.frequency-select-style {
    background-color: #ffffff;
    color: black;
    font-size: 13px;
    height: 33px;
    min-width: 240px;
    border: 1px solid white;
    font-family: 'Arial';
    max-width: 310px;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%, black 50%),
                    linear-gradient(130deg, black 50%, transparent 50%),
                    linear-gradient(to right, transparent, transparent);
    background-position:
        calc(100% - 15px) calc(1em + 1px),
        calc(100% - 10px) calc(1em + 1px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
}

.week-select-style {
    background-color: #ffffff;
    color: black;
    font-size: 13px;
    height: 33px;
    min-width: 100px;
    border: 1px solid white;
    font-family: 'Arial';
    max-width: 310px;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%, black 50%),
                    linear-gradient(130deg, black 50%, transparent 50%),
                    linear-gradient(to right, transparent, transparent);
    background-position:
        calc(100% - 15px) calc(1em + 1px),
        calc(100% - 10px) calc(1em + 1px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
}
.time-select-style {
    border: 1 solid transparent;
    width: 140px;
    padding-Left :0;
    padding-bottom: 5;
}
.react-datepicker__input-container {
      input {
            max-width: 140px;
            border-color: transparent;
            padding-top: 5px;
            }
  }

.locations-dropdown {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    width: 470px;
    font-family: Arial;
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
}

.locations-label {
    margin-top: 15px;
    margin-bottom: 15px;
}

.view-details-form {
    flex: 1;
    width: 610px;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 25px;
}

.submit-button {
    width: 200px;
    background-color: white;
}