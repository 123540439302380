.react-select-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.react-select-inner-container {
    width: 20%;
    display: flex;
    flex-direction: row;
}
.react-select-inner-container-select {
    width: 20%;
    display: flex;
    margin-top: 10px;
    margin-left: 12px;
    flex-direction: row;
    justify-content: center;
}
#load-modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
#tickets-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
#tickets-modal-content {
    width: calc(35%) !important;
    height: 99vh;
    min-width: calc(35%) !important;
    min-height: 99vh;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 60vh;
    min-height: 75vh;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
}
#load-modal-content {
    width: calc(100% - 35px) !important;
    height: 99vh;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
}
#load-modal-body {
    padding: 10px;
}
.react-select-inner-container-select p{
    font-size: 15px;
    align-self: center;
}
.react-select-inner-container p {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
}
.react-select-inner-container b {
    margin-top: 5px;
}
.container-header {
    background-color: white;
    border: solid 1px lightgrey;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.react-select-inner-container-new p {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 0;
}
.react-select-inner-container-new b {
    margin-top: 0;
    padding-right: 7px;
    color: #002960;
    font-size: 15px;
    margin-bottom: 0;
    height: 23px;
}
.container-header p {
    padding-top: 10px;
}
.fontSize {
    font-size: 25px !important;
    padding-left: 10px;
}
.add-customer-button{
  width: 25px;
  height: 25px;
  border: none;
  color: white;
  text-align: center;
  display: inline-block;
  margin: 4px 4px;
  cursor: pointer;
  background-color: #2cb2f3;
  border-radius: 5px;
}
.add-customer-button-div{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 150px;
}
.add-customer-button-text{
    font-size: 13px;
    color: #2cb2f3;
    font-weight: bold;
    padding-top: 15px;
    padding-left: 5px;

}
.react-select-style{
    background-color: #ffffff;
    color: #000000;
    font-size: 13px;
    height: 35px;
    min-width: 150px;
    border-radius: 5px;
    border: 1px solid #002960;
    font-family: 'Arial';
    margin-left: 10px;
    max-width: 310px;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%, black 50%),
                    linear-gradient(130deg, black 50%, transparent 50%),
                    linear-gradient(to right, transparent, transparent);
    background-position:
        calc(100% - 15px) calc(1em + 1px),
        calc(100% - 10px) calc(1em + 1px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
    padding: 0px 10px;
}
.cell-yellow{
    background-color: yellow !important;
}

.cell-blue{
    background-color: lightskyblue !important;
}

.cellColor{
    color: #f5f5f5 !important;
}

#selected-tickets-content {
    width: calc(35%) !important;
    height: 66vh;
    min-width: calc(35%) !important;
    min-height: 66vh;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
}