.checkbox-container {
  display: inline-block;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  user-select: none;

  input {
    display: none;
  }

  &__label {
    color: #5B6682;	
    font-family: Arial;	
    font-size: 14px;	
    line-height: 16px;
    display: block;
    margin-top: 3px;
  }

  &__checkmark {
    display: inline-block;
    width: 18px;
    height: 20px;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #ccc;
  }

  input:checked + .checkbox-container__checkmark {
    background-color: #1390e5;
    border: 1px solid #1390e5;
  }

  input:indeterminate + .checkbox-container__checkmark {
    background-color: #1390e5;
    border: 1px solid #1390e5;
  }

  input:checked + .checkbox-container__checkmark:after {
    content: "";
    position: absolute;
    height: 6px;
    width: 11px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  input:checked:disabled + .checkbox-container__checkmark {
    border: 1px solid grey;
    background-color: grey;
  }

  input:disabled + .checkbox-container__checkmark {
    border: 1px solid grey;
  }

  input:indeterminate + .checkbox-container__checkmark:after {
    content: "";
    position: absolute;
    height: 0px;
    width: 11px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
  }
  
}