.user-request {
    background: url(../../../assets/fullscreen-bg.jpg) 50% 0px no-repeat;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    &_content {
        margin: auto;
        border: 1px solid hsla(0, 0%, 59.2%, 0.23);
        border-radius: 6px;
        background-color: #fff;
        padding: 20px;
        box-shadow: 0 3px 13px 3px rgba(0, 0, 0, 0.04);
        color: #495057;
    }

    &_header {
        color: #0b2d71;
        font-weight: bold;
    }

    &_invalidlink {
        color: red;
        font-weight: bold;
    }

    &_footer {
        padding-top: 15px;
    }
}
