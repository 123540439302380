.date-cell-renderer { 
    width: 100%;
    margin-left: 0px;
    input[type="date"]{
        background-color:#dcf0ff !important;	
    }
    &__isDisabled {
        padding: 10px;
        height: 40px;
        background-color: #FFFFFF;
    }
}