.modal-popup {
    &-header {
        font-size: 16px;
        font-weight: bold;
        color: #0b2d71;
        padding-bottom: 10px;
    }

    &-italic {
        font-style: italic;
        font-size: 12px;
    }
}

button {
    width: 100px;
}

.modal-content {
    height: auto;
    width: 420px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-family: Arial;
}

.modal-body {
    text-align: center;
}
