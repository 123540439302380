.status-bar-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    justify-content: space-around;
    align-items: center;
}
.react-select-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.react-select-inner-container-select {
    width: 20%;
    display: flex;
    margin-top: 10px;
    margin-left: 5px;
    flex-direction: row;
    justify-content: center;
  
}
.react-select-style{
    background-color: #ffffff;
    color: #000000;
    font-size: 13px;
    height: 35px;
    min-width: 150px;
    border-radius: 5px;
    border: 1px solid #002960;
    font-family: 'Arial';
    margin-left: 10px;
    max-width: 310px;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%, black 50%),
                    linear-gradient(130deg, black 50%, transparent 50%),
                    linear-gradient(to right, transparent, transparent);
    background-position:
        calc(100% - 15px) calc(1em + 1px),
        calc(100% - 10px) calc(1em + 1px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
}
.status-bar-container label {
    font-size: 16px;
    font-family: 'Arial';
}
.root {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    border: 1px solid  rgba(0, 0, 0, 0.2);
}
.grid-view-main-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-columns: repeat(7, 1fr);
    // grid-gap: 10px;
    border: 1px solid  rgba(0, 0, 0, 0.2);

}
.outer-grid-view{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;

}
.outer-grid-view p {
    margin-top: 10px;
}
.card {
    width: 100%;
    height: 160px;
}

.filter-main-container {
    width: 20%;
    background-color: #f9f9f9;
    padding: 10px;

}
.filter-container {
    margin-top: 25px;
    
}
.filter-row-style {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid  rgba(0, 0, 0, 0.2);
    justify-content: space-between;
    align-items: baseline;
    margin-left: 5px;
    margin-top: 5px;
    margin-right: 10px;
    
}
.filter-row-style p {
    font-family: 'Arial';
    font-size: 13px;
    width: 150px;

}
.clear-button-style {
    border: none;
    color: #979797;
    padding: 5px 3px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #979797;
    border-radius: 5px;
}
.apply-button-style {
    border: none;
    color: #ffffff;
    padding: 5px 3px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: #4faef0;
    border: 1px solid #4faef0;
    border-radius: 5px;
}

.card-button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 25px ;
    width: 100%;

}
.card-button-container p{
    font-size: 8px;
}
.card-button-container button {
    color: #000000;
    height: 18px;
    width: 30px;
    border: none;
    font-size: 8px;
    font-weight: bold;
}
.circle {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}
.filter-container p {
   text-align: left;
}
.region-company-style{
    display: flex;
    flex-direction: column;

}
.region-company-style p{
    font-size: 11px;
}
.inner-container {
    display: flex;
    flex-direction: column;
}
.inner-container label {
    color: #000000;
    font-family: 'Arial';
    font-size: 15px;
}
.paper-style {
    width: 100%;
}
.table-container {
    max-height: 1000px;

}
.table {
    position: sticky;

}

.dialog-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    align-self: center;
}
.dialog-title {
    background-color: #eaf6fe;
    width: 90%;
    min-height: 100%;
    font-size: 13px;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 50px;

}
.dialog-title p{
   margin-left: 10px;
   margin-top: 12px;

}
.popover-close-button {
    background-color: transparent;
    width: 30px;
    border: none;
    font-size: 18px;
    color: #313334;
    margin-right: 15px;

}
.table-circle {
    width: 8px;
    height: 8px;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 5px;
}
.button-container{
    display: flex;
    flex-direction: row;
}
.circle-container-table{
    display: flex;
    flex-direction: row;
    height: 20px;
}
.dialog-main-container{
    align-self: center;
    width: 90%;
}
.dispatch-board-main-container{
    align-self: center;
    width: 100%;
    padding: 0 !important;
}

.additional-container {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.additional-label{
    color: #1eb2f3;
    font-size: 12px;
    width: 170px;
    align-self: flex-start;
    text-align: left;
    border: 1px solid transparent;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    background-color: transparent;
}

.plannedDatePopUp{
    width: 500px;
   background-color: #FFFFFF;	
   box-shadow: 0 0 10px 0 #9B9B9B;
   display: flex;
   flex-direction: column;
   padding: 20px;
   max-height: 1000px;
   min-height: 350px;
   overflow-y: auto;
   min-width: 225px;
}
.startDate-container{
  flex-direction: column;
  margin-bottom: 5px;
}
.endDate-container{
  flex-direction: column;
  margin-bottom: 5px;
  margin-left: 10px;
}
