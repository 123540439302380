.assigned-shop-item {
    border-radius: 6px;
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;
    color: #002960;
    line-height: 21px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    &__content {
        font-weight: normal;
        font-size: 11px;
        margin-left: -20px;
    }
    &__location {
        font-weight: normal;
        color: rgb(22, 95, 196);
        text-decoration: underline;
    }
}
