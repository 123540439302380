.data-grid {
    > div:first-child {
        height: calc(100vh - 200px) !important;
    }
}

#DailyReport, #MasterTicketReport, #LeaseOperatorReport, #CustomerReport, #RejectedLoads, #Nomination {
    > div:first-child {
        height: calc(100vh - 330px) !important;
    }
}

#DispatchPlan {
    > div:first-child {
        height: calc(100vh - 325px) !important;
    }
}

#DispatchScreen {
    > div:first-child {
        height: calc(100vh - 375px) !important;
    }
}

#InspectionTicketHistory, #TrailerBookingMaintenance, #HazardAssessmentHistory, #UpcomingCertificationReport, #Communication, #BroadCast {
    > div:first-child {
        height: calc(100vh - 260px) !important;
    }
}

#TicketApproval {
    > div:first-child {
        height: calc(100vh - 270px) !important;
    }
}

#UserApproval{
    > div:first-child {
        height: calc(100vh - 150px) !important;
    }
}

#NominationLoad {
    > div:first-child {
        height: calc(100vh - 265px) !important;
    }
}
#SearchTickets, #Apportionment {
    > div:first-child {
        height: calc(100vh - 310px) !important;
    }
}

.ag-cell-wrapper.ag-row-group {
    align-items: center !important; 
}