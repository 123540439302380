.btn-primaryrounded {
    font-family: Raleway-SemiBold;
    font-size: 13px;
    color: rgb(58, 133, 191);
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid rgb(58, 133, 191);
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
    margin-bottom: 10px;
    margin-right: 10px;
    font-family: Arial Regular;
    font-size: 12px;
    width: 90%;
}

.selDefaultSize {
    min-height: 225px !important;
}

.SelMapped {
    min-height: 200px;
    display: block;
    font-size: 12px;
    font-family: Arial Regular;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: unset, unset, 5px, unset;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 0px;
    outline: 0px;
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    width: 100%;
    font-family: Arial Regular;
    font-size: 12px;
    margin-bottom: 16Px;
}

.NotMapped {
    font-family: Arial Regular;
    font-size: 12px;
}

.divsubmit {
    border-radius: 6px;
}

.Margindiv {
    margin-top: 16%;
}

.filterclass {
    width: 90%;
    font-size: 15px;
    box-sizing: border-box;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    background-color: #F6F5F5;
    margin-bottom: 8px;
    height: 12%;
}

#Facility option {
    height: 26px;
    padding-top: 7px;
}

option:nth-child(odd) {
    box-sizing: border-box;
    height: 45px;
    border: 1px solid #E7E7E7;
    background-color: #FFFFFF;
    padding-top: 15px;
    padding-left: 7px;
    font-family: Arial Regular;
    font-size: 12px;
}

option:nth-child(even) {
    height: 44px;
    background-color: #FAFAFA;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
    padding-top: 15px;
    padding-left: 7px;
    font-family: Arial Regular;
    font-size: 12px;
}

.Mapped {
    font-family: Arial Regular;
    font-size: 12px;
}

.lnkactive {
    font-family: Raleway-SemiBold;
    font-size: 13px;
    color: rgb(58, 133, 191);
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid rgb(58, 133, 191);
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
}

.btnSubmitAll {
    font-size: 20px;
    margin-top: 25px;
    margin-right: 20%;
    width: 140%;
    height: 45px;
    width: 129px;
    border-radius: 4px;
    background-color: #3389D2;
    box-shadow: 0 3px 13px 3px rgba(155, 155, 155, 0.06);
    color: #FFFFFF;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
}


/*.col{
  border: 1px solid rgba(151, 151, 151, 0.23)!important;
}*/
.searchMultiple {
    height: 34px;
    border: 1px solid rgba(151, 151, 151, 0.23);
    width: 100%!important;
}

.searchbox {
    height: auto;
    border: 1px solid rgba(151, 151, 151, 0.23);
    background-color: #FFFFFF;
    box-shadow: 0 3px 13px 3px rgba(0, 0, 0, 0.04);
    vertical-align: middle;
    text-align: center;
    width: 100%!important;
    margin: 3%;
}

.v1 {
    border-left: 1px;
    height: auto;
    margin-left: 4%;
    margin-right: 4%;
    border-left-style: outset
}

.ion-android-arrow-dropdown-ion {
    height: 14px;
    width: 7px;
    color: #FFFFFF;
    font-family: Ionicons;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: NaNpx;
}

input.placeholder {
    //text-align: center;
    margin-left: 10%;
}


/*
select:focus {
  width: auto;
  min-width: 250px;
 // z-index: 10;
}*/

.lbl {
    font-size: 10px;
    font-weight: bold;
    padding-right: 10px;
}

::-webkit-input-placeholder {
    text-align: left;
    font-size: 12px;
    // margin-left: 10%;
    // padding-left: 20px;
}

:-moz-placeholder {
    /* Firefox 18- */
    text-align: left;
    font-size: 12px;
    padding-left: 20px;
    margin-left: 10%;
}

::-moz-placeholder {
    /* Firefox 19+ */
    text-align: left;
    font-size: 12px;
    padding-left: 20px;
    margin-left: 10%;
}

:-ms-input-placeholder {
    text-align: left;
    font-size: 12px;
    padding-left: 20px;
    margin-left: 10%;
}

.parentmargin {
    margin-left: 1%;
}

.selectbox {
    height: 8%;
    color: white;
    width: 100% !important;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    border-radius: inherit;
    align-content: end;
    vertical-align: middle;
    text-align: center;
    height: 43px;
    width: 233px;
    border-radius: 4px 4px 0 0;
    background-color: #3389D2;
    font-family: Arial;
    font-weight: bold;
    font-size: 10px;
}

.divinner {
    padding: 1%;
    display: flex;
}

.green {
    color: green($color: #000000);
}

.pdiv {
    //margin-left: 3%;
    margin-top: 1%;
}

.EnableClass {
    background-color: aliceblue;
    //cursor: pointer!important;
}

.disable {
    background-color: #F6F5F5;
    //cursor: no-drop!important;
}
