.tickets-popup {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.5);
 }
.current-status {
    display: flex;
    align-items: center;
    &__indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
    &__assigned {
        background-color: #589F07;
    }
    &__not-assigned {
        background-color: #F5A623;
    }
    &__text {
        margin-left: 6px;
        color: rgb(22, 95, 196);
    }
    &__carrier {
        color: rgb(0, 0, 0);
    }
}

.ag-cell {
    input {
        height: 40px;
        border: 0px;
    }
}

.delete-cell {
    cursor: pointer;
}

.fa-disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }