.font-icon {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &__box {
        height: 35px;	
        width: 40px;	
        border: 0.5px solid #DCDCDC;	
        border-radius: 3px;	
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__label {
        color: #4A90E2;
    }
}