.infopage {
    &__defaultpage {
        display: flex;
        flex-direction: column;
        width: 320px;
        // align-items: center;
    }
    &__parent_container {
        box-sizing: border-box;
        flex: 1;
        height: 95vh;
        border: 1px solid rgba(151, 151, 151, 0.23);
        border-radius: 6px;
        background-color: #ffffff;
        // align-items: center;
        padding: 0 0 0 30px;
        display: flex;
        flex-direction: column;
    }
    &__header {
        font-size: 22px;
        line-height: 20px;
        height: 19px;
        width: 144px;
        font-weight: bold;
        margin-top: 47px;
        color: #002960;
        font-family: Arial;
    }
    &__list {
        margin-top: 16px;
        color: #4A90E2;
        font-family: Arial;
        font-size: 16px;
        line-height: 17px;
        cursor: pointer;
    }
}
