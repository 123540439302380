.maintenance-update-popup {
    &__parent {
        border: 2px black solid;
        height: auto;
        padding: 15px;
        text-align: left;
        .react-datepicker__input-container{
            font-size: 14px;
            input {
                max-width: 140px;
                border-color: #002960 !important;
                padding-top: 5px;
            }
        }
    }
    &__type {
        width: auto;
        font-family: Arial;
        background-color: #002960;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        flex-direction: row;
    }
    &__label {
        height: 14px;
        margin-left: 20px;
        color: #002960;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 14px;
        margin-right: 10px;
    }
    &__buttongroup {
        display: flex;
        justify-content:center;
    }
    &__labelMaitn {
        height: 14px;
        color: #002960;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 14px;
        margin-right: 10px;
    }

    &__quesContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 20px;
        margin-top: 10px;
        width: 200px;
    }

    &__ques {
        display: flex;
        justify-content: space-between;
    }

    &__ques {
        display: flex;
        justify-content: space-between;
    }

    &__quesCheckBox {
       margin-top: 2px;
       margin-right: 25px;
       width: 15px;
       height: 15px;
    }

}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}
